<template>
  <div class="list">
    <el-row class="list-filter" type="flex" justify="space-around" align="middle">
      <el-col class="list-filter-title" :span="2">饮品库</el-col>
      <el-col class="list-filter-cond input" :span="9" :offset="3">
        <el-input
          v-model="pagination.name"
          placeholder="输入饮品名称"
          size="large"
          @search="loadTmpl"
          @change="loadTmpl"
          enter-button="搜索">
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
          <el-button slot="append">检索</el-button>
        </el-input>
      </el-col>

      <el-col :span="3">
        <el-select
          v-model="uuid"
          class="list-filter-select"
          placeholder="选取品牌"
          size="large"
          filterable
          @change="handleSelectBrand"
        >
          <el-option v-for="(brand, idx) in brands" :key="idx"
                           :label="brand.name" :value="brand.uuid">
            {{ brand.name }}
          </el-option>

        </el-select>
      </el-col>
      <el-col :span="5">
        <el-select
          v-model="productUuid"
          class="list-filter-select"
          placeholder="选取机型"
          size="large"
          filterable
          @change="handleSelect"
        >
          <el-option v-for="(product, idx) in products" :key="idx"
                           :label="product.name" :value="product.uuid">
            <span class="select-item-title">{{ product.name }}</span>
            <span class="select-item-label">{{ product.explain }}</span>
          </el-option>

        </el-select>
      </el-col>
      <el-col :span="3" :offset="1">
        <el-button v-if="$btnCheck('product_create')" type="primary"
                  @click="$router.push({name: 'equipment.drinks.create'})">创建饮品
        </el-button>
      </el-col>
    </el-row>

    <div class="list-container">
      <div class="list-container-item" v-for="(drink, key) in drinks" :key="key">
        <div class="container-item" @click="handleDetail(drink.uuid)">
          <div class="container-item-drink">
            <img class="drink-img" :src="drink.image"/>
            <div class="drink-info">
              <div class="drink-info-name">
                <p class="drink-info-name-eng">{{drink.engName}}</p>
                <p class="drink-info-name-locale">{{drink.name}}</p>
              </div>

              <div class="drink-info-price">
                <p>参考零售价</p>
                <div class="price-container">
                  <p v-for="(spec,idx) in drink.drinksSpec" :key="idx">
                    <span>{{spec.price/100 | F1000}}</span>
                    <span>{{spec.name}}</span>
                  </p>
                </div>


              </div>
            </div>
          </div>
          <div>
            <div class="container-item-text">
              <span class="name">饮品说明:</span>
              <span class="product">{{drink.explain}}</span>
            </div>
          </div>
          <el-row class="container-item-opr">
            <el-col :span="12" class="copy">
              <el-icon type="copy"/>
              查看
            </el-col>
            <el-col :span="12" class="delete">
              <span @click.stop.prevent="handleDelete(drink.uuid)">
                <el-icon type="delete"/>
              删除
              </span>
            </el-col>
          </el-row>
          <span v-if="drink.isDefault" class="container-item-flag">官方</span>
        </div>

      </div>

    </div>
  </div>

</template>

<script>

  export default {
    name: 'org-drink-list',
    components: {},
    mounted: function () {
      this.$api('equipment.brand.all').then(data => {
        this.brands = data || []
      })
      this.loadTmpl()
    },
    data: function () {
      return {
        products: [],
        brands: [],
        value: undefined,
        drinks: [],
        uuid:'',
        productUuid:'',
        pagination: {
          name: '',
          moduleUuid: 0,
          start: 0,
          limit: 10,
          pageSize: 10,
          current: 1,
          total: 5,
          onChange: (current) => this.handleNext(current),
        },
      }
    },
    methods: {
      handleSelectBrand(value) {
        this.$api('equipment.brand.products', {uuid: value}).then(data => {
          this.products = data || []
        })
      },
      handleSelect(val) {
        this.pagination.moduleUuid = val
        this.loadTmpl()
      },
      handleDetail(uuid) {
        this.$router.push({
          name: 'equipment.drinks.detail',
          params: {
            uuid: uuid,
          }
        })
      },
      loadTmpl() {
        this.pagination.start = 0
        let pagination = Object.assign({}, this.pagination)
        pagination.onChange = null
        this.$api('equipment.drinks.union', pagination).then(data => {
          this.pagination.total = parseInt(data.total)
          this.drinks = data.list || []
        })
      },
      handleNext(page) {
        this.pagination.start = (page - 1) * this.pagination.limit
        let pagination = Object.assign({}, this.pagination)
        pagination.onChange = null
        this.$api('equipment.drinks.list', this.pagination).then(data => {
          this.drinks = data || []
        })
      },
      handleDelete(uuid) {
        let vue = this
        this.$confirm('删除饮品不影响已有饮品', '是否删除饮品').then(() => {
          this.$api('equipment.drinks.delete',{uuid}).then(() => {
              vue.loadTmpl()
            })
          }).catch(() => {})
        }
      },
  }
</script>

<style lang="scss" scoped>
  .select-item-title {
    @include font-medium();
  }

  .select-item-label {
    @include font-little-h();
    padding-top: 6px;
    float: right;
  }

  .select-item-label:after{
    @include clearfix();
  }

  .list {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;


    &-filter {
      padding: $container-padding;
      background-color: $color-white;

      &-title {
        @include font-large-s();
      }

      &-select {
        width: 95%;
        margin-left: 5%;
      }

    }

    ::v-deep .el-select-dropdown__item{
      height: 64px;
    }

    &-container {
      flex: 1;
      padding: $container-padding;
      display: flex;
      flex-wrap: wrap;
      height: 100%;

      &-item {
        width: 24%;

        .container-item {
          position: relative;
          padding: $middle-space;
          background-color: $color-white;
          cursor: pointer;
          box-shadow: $box-shadow;

          &-drink {
            display: flex;
            padding-bottom: $small-space;
            margin-bottom: $small-space;
            border-bottom: 1px solid $content-border-color;

            .drink-img {
              width: 33%;
              max-height: 100px;
              margin: $small-space;
            }

            .drink-info {
              flex: 1;
              margin-left: $small-space;

              &-name {
                &-eng {
                  @include font-large-s();
                  font-weight: 500;
                  color: $color-black;
                }

                &-locale {
                  color: $color-black;
                }
              }

              &-price {
                margin-top: $small-space;
                @include font-little();

                .price-container {
                  display: flex;

                  p {
                    flex: 0 0 33.33%;

                    span {
                      display: block;
                      text-align: left;

                    }
                  }
                }
              }
            }
          }

          &-flag {
            position: absolute;
            top: $middle-space;
            right: $middle-space;
            color: $color-white;
            font-weight: 600;
            @include font-little();
            background-color: $theme-color;
            padding: 1px 2px;
            border-radius: 2px;

          }

          &-text {
            display: flex;
            @include font-little();

            .name {
              margin-right: 4px;
            }

            .remark, .product {
              flex: 1;
            }
          }

          &-opr {
            text-align: center;
            margin-top: $small-space;

            .copy {
              color: $theme-color
            }

            .delete {
              color: $brand-danger;
            }
          }
        }



      }

      &-item:not(:nth-child(4n)) {
        margin-right: calc(4% / 3);
      }
    }
  }
</style>
